import {
  startOfDay,
  endOfDay,
  subYears,
  subDays,
  startOfMonth,
} from 'date-fns';
import GMPAPI from '../../../../../services/gmpapi';
import config from './MyImpactListViewModelConfig.json';
import { DumpError, ToServerDateTruncate } from '../../../../../utilities';

export default class MyImpactListViewModel {
  constructor() {
    this.impacts = undefined;
    this.loadState = undefined;
    this.endDate = endOfDay(new Date());
    this.startDate = startOfDay(subYears(new Date(), 1));

    Object.assign(this, config);
  }

  getQueryString() {
    return MyImpactListViewModel.buildDateRangeQueryString(this.startDate, this.endDate);
  }

  static buildDateRangeQueryString(startDate, endDate) {
    const startDateFormatted = ToServerDateTruncate(startDate);
    const endDateFormatted = ToServerDateTruncate(endDate).replace('T00:00:00', 'T23:59:59');

    return `?startDate=${startDateFormatted}&endDate=${endDateFormatted}`;
  }

  async getImpacts(accountNumber, queryString) {
    this.loadState = undefined;

    try {
      this.impacts = await GMPAPI.GetImpact(accountNumber, queryString);
      this.loadState = 'complete';
    } catch (err) {
      DumpError('getImpacts error', err);
      this.loadState = 'error';
    }

    return this.impacts;
  }

  onDateRangeSelected($event, accountNumber) {
    switch ($event) {
    case 'last-7-days':
      this.startDate = startOfDay(subDays(new Date(), 7));
      break;
    case 'last-2-weeks':
      this.startDate = startOfDay(subDays(new Date(), 14));
      break;
    case 'last-30-days':
      this.startDate = startOfDay(subDays(new Date(), 30));
      break;
    case 'current-month':
      this.startDate = startOfMonth(new Date());
      break;
    case 'last-6-months':
      this.startDate = startOfDay(subDays(new Date(), 180));
      break;
    case 'year-to-date':
      this.startDate = startOfDay(new Date(new Date().getFullYear(), 0, 1));
      break;
    default:
      this.startDate = startOfDay(subYears(new Date(), 1));
    }

    this.getImpacts(accountNumber, this.getQueryString());
  }
}
