<template>
  <div
    v-if="FF_Devices"
    class="gmp-devices-page__overview"
  >
    <AddDeviceStart v-if="noDevices" />
    <div v-if="!loadState" class="table-loading" />
    <div v-if="hasDevices">
      <div class="header-container gds-space-stack-l">
        <h1 class="my-account__title gds-space-stack-default">Devices</h1>
      </div>
      <div class="devices-overview-grid">
        <div
          class="devices-overview__widget devices-overview__widget__devices-list"
        >
          <DevicesList
            :devices="devices"
          />
        </div>
        <div
          class="devices-overview__widget devices-overview__widget__my-impact-list"
        >
          <MyImpactList />
        </div>
      </div>
    </div>
    <AutoTimer
      :repeat-mode="true"
      :timer-seconds="60"
      :on-timer-execution="refreshDeviceDetail"
    />
  </div>
</template>

<script>
import GMPAPI from '../../../../services/gmpapi';
import { DumpError } from '../../../../utilities';
import DevicesList from './deviceslist/DevicesList.vue';
import MyImpactList from './myimpactlist/MyImpactList.vue';
import AutoTimer from '../../../shared/autotimer/AutoTimer.vue';
import { isFeatureEnabled } from '../../../../services/featureflags';
import AddDeviceStart from '../adddevice/adddevicestart/AddDeviceStart.vue';
import DeviceDetailViewModel from '../devicedetail/viewmodels/DeviceDetailViewModel';

export default {
  name: 'DevicesOverview',
  components: {
    AutoTimer,
    DevicesList,
    MyImpactList,
    AddDeviceStart,
  },
  data() {
    return {
      devices: undefined,
      loadState: undefined,
      FF_Devices: undefined,
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    devicesLoaded() {
      return this.loadState === 'complete' && this.devices;
    },
    hasDevices() {
      return this.devicesLoaded && this.devices.length;
    },
    noDevices() {
      return this.devicesLoaded && !this.devices.length;
    },
  },
  watch: {
    currentAccount() {
      this.getOverviewData(this.currentAccount.accountNumber);
    },
  },
  async mounted() {
    await this.checkFeatureFlag();

    if (this.currentAccount && this.currentAccount.accountNumber) {
      this.getOverviewData(this.currentAccount.accountNumber);
    }
  },
  methods: {
    async checkFeatureFlag() {
      this.FF_Devices = await isFeatureEnabled('FF_Devices', false);

      if (!this.FF_Devices) {
        window.location = '/account';
      }
    },
    async setDevicesToLoadingState(devices) {
      this.devices = devices.map((device) => ({
        ...device,
        loading: true,
      }));
    },
    async getOverviewData(accountNumber) {
      this.loadState = undefined;

      try {
        const [devicesResponse] = await Promise.all([
          GMPAPI.GetDevices(accountNumber),
        ]);

        this.setDevicesToLoadingState(devicesResponse);

        this.loadState = 'complete';

        this.refreshDeviceDetail();
      } catch (err) {
        DumpError('Get Overview Data error', err);
        this.loadState = 'error';
      }
    },
    async refreshDeviceDetail() {
      if (!this.devices || !this.devices.length) {
        return;
      }

      this.setDevicesToLoadingState(this.devices);

      const queryString = DeviceDetailViewModel.GetDeviceDetailQueryString(this.currentAccount);

      try {
        const deviceDetailPromises = this.devices.map((device) => GMPAPI.GetDeviceDetail(
          this.currentAccount.accountNumber,
          device.deviceId,
          queryString,
        ));

        this.devices = await Promise.all(deviceDetailPromises);
      } catch (err) {
        DumpError('Refresh Device Detail error', err);
        this.loadState = 'error';
      }
    },
  },
};
</script>
