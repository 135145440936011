<template>
  <div v-if="viewModel.FF_Devices" class="gmp-devices-page__device-detail">
    <div class="device-detail-grid">
      <BackButton v-if="isDesktopView" :route="'/devices'" />
      <DeviceDetailSummary v-if="viewModel.device" :device="viewModel.device" />
      <div v-if="!viewModel.loadState" class="table-loading" />
      <template v-if="viewModel.loadState">
        <PeakEvents v-if="viewModel.device && viewModel.device.displayEvents && viewModel.device.events" :energy-events="viewModel.device.events" />
        <ImpactCardGrid
          v-if="viewModel.device && viewModel.device.stats && viewModel.device.stats.length"
          title="Quick Stats"
          :impacts="viewModel.device.stats"
        />
        <EvChargingHistory v-if="evChargingHistoryShouldShow" :ev-charging-history-data="viewModel.evHistoryChargeData" />
        <BatteryChargeLevel v-if="batteryChargeLevelShouldShow" :battery-charge-data="viewModel.batteryChargeData" />
        <PeakRateSchedule v-if="viewModel.device && viewModel.device.peakRateSchedule" :peak-rate-schedule="viewModel.device.peakRateSchedule" />
        <DeviceInfo v-if="viewModel.deviceInfo" :device-info="viewModel.deviceInfo" />
      </template>
    </div>
    <AutoTimer
      :repeat-mode="true"
      :timer-seconds="60"
      :on-timer-execution="refreshDeviceDetail"
    />
  </div>
</template>

<script>
import MediaQueryMixin from '../../../mixins/MediaQueryMixin';
import DeviceDetailViewModel from './viewmodels/DeviceDetailViewModel';

import DeviceInfo from './deviceinfo/DeviceInfo.vue';
import PeakEvents from './peakevents/PeakEvents.vue';
import AutoTimer from '../../../shared/autotimer/AutoTimer.vue';
import EvChargingHistory from './EvChargingHistory/EvChargingHistory.vue';
import BackButton from '../shared/components/formcomponents/BackButton.vue';
import BatteryChargeLevel from './batterychargelevel/BatteryChargeLevel.vue';
import DeviceDetailSummary from './devicedetailsummary/DeviceDetailSummary.vue';
import ImpactCardGrid from '../shared/components/impactcard/ImpactCardGrid.vue';

import PeakRateSchedule from './peakrateschedule/PeakRateSchedule.vue';

export default {
  name: 'DeviceDetail',
  components: {
    AutoTimer,
    BackButton,
    PeakEvents,
    DeviceInfo,
    ImpactCardGrid,
    PeakRateSchedule,
    EvChargingHistory,
    BatteryChargeLevel,
    DeviceDetailSummary,
  },
  mixins: [MediaQueryMixin],
  props: {
    device: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      viewModel: new DeviceDetailViewModel(),
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    batteryChargeLevelShouldShow() {
      const vm = this.viewModel;
      return vm.deviceType === vm.constants.DEVICE_TYPE_BATTERY && vm.batteryChargeData.length;
    },
    evChargingHistoryShouldShow() {
      const vm = this.viewModel;
      return vm.deviceType === vm.constants.DEVICE_TYPE_EV_CHARGER && vm.evHistoryChargeData.length;
    },
    isDesktopView() {
      return this.deviceIsExtraLarge
        || this.deviceIsExtraExtraLarge
        || this.deviceExceedsExtraExtraLarge;
    },
  },
  watch: {
    currentAccount() {
      this.$router.push('/devices');
    },
  },
  async mounted() {
    const vm = this.viewModel;
    await vm.checkFeatureFlag();

    vm.deviceId = this.$route.params.id;

    if (this.device) {
      vm.device = this.device;
      vm.getChartData(this.currentAccount.accountNumber);

      if (vm.device.status && vm.device.status.operationalMode) {
        vm.device.status.operationalMode = DeviceDetailViewModel.FormatOperationalMode(vm.device.status.operationalMode);
      }
    }

    await vm.getDeviceDetail(this.currentAccount.accountNumber, this.GetDeviceDetailQueryString(), false);
    await vm.getDeviceInfo(this.currentAccount.accountNumber, vm.deviceId, true);

    if (!vm.chartDataLoaded) {
      await this.viewModel.getChartData(this.currentAccount.accountNumber);
    }
  },
  methods: {
    GetDeviceDetailQueryString() {
      return DeviceDetailViewModel.GetDeviceDetailQueryString(this.currentAccount);
    },
    refreshDeviceDetail() {
      this.viewModel.getDeviceDetail(this.currentAccount.accountNumber, this.GetDeviceDetailQueryString(), true);
    },
  },
};
</script>
