<template>
  <div class="devices-max-width-my-account">
    <div
      class="
      header-container
      gds-flex-container--left
      devices-gap-2 gds-flex-container--wrap
      gds-flex-container gds-flex-container--space-between">
      <h2 class="devices-overview-sub-header gds-space-stack-default">My Impact</h2>
      <DevicesSelectControl :config="vm.devicesSelectControlConfig" :option-selected="onDateRangeSelected" />
    </div>
    <div v-if="!vm.loadState" class="table-loading" />
    <ImpactCardGrid v-if="impactsLoaded" :impacts="vm.impacts" />
    <flow-error
      v-if="vm.loadState === 'error'"
      name="Impact information"
      state="unavailable"
      img="/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg"
    />
  </div>
</template>

<script>
import MyImpactListViewModel from './MyImpactListViewModel';
import ImpactCardGrid from '../../shared/components/impactcard/ImpactCardGrid.vue';
import DevicesSelectControl from '../../shared/components/DevicesSelectControl.vue';

export default {
  name: 'MyImpactList',
  components: {
    ImpactCardGrid,
    DevicesSelectControl,
  },
  data() {
    return {
      vm: new MyImpactListViewModel(),
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    impactsLoaded() {
      return this.vm.loadState === 'complete' && this.vm.impacts && this.vm.impacts.length;
    },
  },
  mounted() {
    this.onDateRangeSelected(this.vm.devicesSelectControlConfig.selectedValue);
  },
  methods: {
    async onDateRangeSelected($event) {
      await this.vm.onDateRangeSelected($event, this.currentAccount.accountNumber);
    },
  },
};
</script>
