var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "devices-max-width-my-account" },
    [
      _c(
        "div",
        {
          staticClass:
            "\n    header-container\n    gds-flex-container--left\n    devices-gap-2 gds-flex-container--wrap\n    gds-flex-container gds-flex-container--space-between"
        },
        [
          _c(
            "h2",
            {
              staticClass: "devices-overview-sub-header gds-space-stack-default"
            },
            [_vm._v("My Impact")]
          ),
          _vm._v(" "),
          _c("DevicesSelectControl", {
            attrs: {
              config: _vm.vm.devicesSelectControlConfig,
              "option-selected": _vm.onDateRangeSelected
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      !_vm.vm.loadState
        ? _c("div", { staticClass: "table-loading" })
        : _vm._e(),
      _vm._v(" "),
      _vm.impactsLoaded
        ? _c("ImpactCardGrid", { attrs: { impacts: _vm.vm.impacts } })
        : _vm._e(),
      _vm._v(" "),
      _vm.vm.loadState === "error"
        ? _c("flow-error", {
            attrs: {
              name: "Impact information",
              state: "unavailable",
              img:
                "/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }